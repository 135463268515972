import Projects from "./Projects";

function ProjectsPage() {
    return (
        <>
            <Projects />
        </>
    );
}

export default ProjectsPage;